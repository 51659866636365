<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-form :model="userForm" ref="userForm" style="margin-bottom: 20px;">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="用户名" name="username">
              <a-input v-model:value="userForm.username" placeholder="用户名" :allowClear="true" style="width: 80%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号" name="phone">
              <a-input v-model:value="userForm.phone" placeholder="手机号" :allowClear="true" style="width: 80%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="邮箱" name="mail">
              <a-input v-model:value="userForm.mail" placeholder="邮箱" :allowClear="true" style="width: 80%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否锁定" name="isLock">
              <a-select v-model:value="userForm.isLock" :allowClear="true" style="width: 80%;">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="16">
            <a-form-item label="创建时间">
              <a-date-picker v-model:value="userForm.createTimeBegin" placeholder="开始时间" show-time valueFormat="YYYY-MM-DD HH:mm:ss" />
              ~
              <a-date-picker v-model:value="userForm.createTimeEnd" placeholder="结束时间" show-time valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" style="text-align: center">
            <a-button type="primary" @click="onSearch">查询</a-button>
            <a-button style="margin: 0 20px" @click="clearForm">清空</a-button>
          </a-col>
        </a-row>

      </a-form>

      <a-button class="editable-add-btn" style="margin-bottom: 10px; margin-right: 10px;" @click="onRegister">注册用户</a-button>
      <a-button class="editable-add-btn" style="margin-bottom: 10px; margin-right: 10px;" @click="onBatchDelete">批量删除</a-button>

      <a-table rowKey="id" :dataSource="dataSource" :columns="columns" bordered :scroll="{ x: 1500 }"
               :pagination="false" :loading="loading" style="margin-bottom: 10px;"
               :row-selection="rowSelection"
               @change="handleTableChange">
        <template #bodyCell="{ column, record, index }">

          <template v-if="column.key === 'xid'">
            <span>
              {{ index + 1 + (userForm.currentPage - 1) * userForm.pageSize }}
            </span>
          </template>

          <template v-if="column.key === 'isLock'">
            <span>
              <a-tag v-if="record.isLock === 1" color="red">
                是
              </a-tag>
              <a-tag v-else-if="record.isLock === 0" color="green">
                否
              </a-tag>
              <a-tag v-else>
                {{ record.isLock }}
              </a-tag>
            </span>
          </template>

          <template v-if="column.key === 'operation'">
            <span>
              <a><a-tag color="purple" @click="details(record.id)" >详情</a-tag></a>
              <a><a-tag color="processing" @click="onUpdate(record.id)" >修改</a-tag></a>
              <a-popconfirm
                      title="确认删除？"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="() => onDelete([record.id])">
                <a><a-tag color="error">删除</a-tag></a>
              </a-popconfirm>
            </span>
          </template>

        </template>
      </a-table>

      <a-pagination :current="userForm.currentPage" :defaultPageSize="userForm.pageSize" :total="total" @change="onPagination"
                    style="text-align: right; margin-bottom: 20px;"
                    show-size-changer show-quick-jumper :show-total="total => `共 ${total} 条数据`" />

      <a-modal v-model:visible="showDetails" title="详情" style="width: 70%;" :footer="null" :afterClose="() => {this.userDetails = {}; this.userDeptRoleList = []}">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="姓名">
              {{userDetails.realname}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="性别">
              <div v-if="userDetails.gender === 1">
                男
              </div>
              <div v-else-if="userDetails.gender === 0">
                女
              </div>
              <div v-else>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证">
              {{userDetails.idcard}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              {{userDetails.phone}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="邮箱">
              {{userDetails.mail}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="用户名">
              {{userDetails.username}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="昵称">
              {{userDetails.nickname}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="头像">
              <a-avatar :src="userDetails.avatar" size="large" style="color: #f56a00; background-color: #fde3cf">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="生日">
              {{userDetails.birthday}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="住址">
              {{userDetails.address}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="创建时间">
              {{userDetails.createTime}}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否锁定">
              <a-tag v-if="userDetails.isLock === 1" color="red">
                是
              </a-tag>
              <a-tag v-else-if="userDetails.isLock === 0" color="green">
                否
              </a-tag>
            </a-form-item>
          </a-col>
        </a-row>

        <a-table :dataSource="userDeptRoleList" :columns="detailsColumns" :pagination="false" style="margin-bottom: 20px">
        </a-table>

      </a-modal>

      <a-modal v-model:visible="showUpdate" title="修改" style="width: 70%;" :footer="null" :afterClose="() => {this.userDetails = {}; this.userDeptRoleList = []}" @ok="confirmUpdate">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="姓名">
              <a-input v-model:value="userDetails.realname" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="性别">
              <a-select v-model:value="userDetails.gender" style="width: 80%;">
                <a-select-option :value="1">男</a-select-option>
                <a-select-option :value="0">女</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证">
              <a-input v-model:value="userDetails.idcard" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input v-model:value="userDetails.phone" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="邮箱">
              <a-input v-model:value="userDetails.mail" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="用户名">
              <a-input v-model:value="userDetails.username" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="昵称">
              <a-input v-model:value="userDetails.nickname" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="头像">
              <a-input v-model:value="userDetails.avatar" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="生日">
              <a-date-picker v-model:value="userDetails.birthday" show-time valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 80%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="住址">
              <a-input v-model:value="userDetails.address" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否锁定">
              <a-select v-model:value="userDetails.isLock" style="width: 80%;">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="密码">
              <a-input-password v-model:value="userDetails.password" style="width: 80%;"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" style="text-align: center; margin-bottom: 50px;">
            <a-button type="primary" @click="confirmUpdate">确定</a-button>
          </a-col>
        </a-row>

        <a-select style="margin-bottom: 10px; margin-right: 10px; width: 20%;" placeholder="请选择部门" v-model:value="deptSelectValue">
          <a-select-option v-for="deptSelect in this.deptSelectList" :key="deptSelect.value" :value=deptSelect.value>{{ deptSelect.label }}</a-select-option>
        </a-select>

        <a-select style="margin-bottom: 10px; margin-right: 10px; width: 20%;" placeholder="请选择角色" v-model:value="roleSelectValue">
          <a-select-option v-for="roleSelect in this.roleSelectList" :key="roleSelect.value" :value=roleSelect.value>{{ roleSelect.label }}</a-select-option>
        </a-select>

        <a-button class="editable-add-btn" style="margin-bottom: 10px; margin-right: 10px;" @click="authorization">授权</a-button>

        <a-table :dataSource="userDeptRoleList" :columns="updateColumns" :pagination="false" style="margin-bottom: 20px">
          <template #bodyCell="{ column, record }">

            <template v-if="column.key === 'operation'">
              <span>
                <a @click="cancelAuthorization(record)">取消授权</a>
              </span>
            </template>

          </template>

        </a-table>
      </a-modal>

      <a-modal v-model:visible="showCreate" title="注册" style="width: 70%;" :footer="null" :afterClose="() => {this.userDetails = {};}">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="姓名">
              <a-input v-model:value="userDetails.realname" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="性别">
              <a-select v-model:value="userDetails.gender" style="width: 80%;">
                <a-select-option :value="1">男</a-select-option>
                <a-select-option :value="0">女</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证">
              <a-input v-model:value="userDetails.idcard" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input v-model:value="userDetails.phone" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="邮箱">
              <a-input v-model:value="userDetails.mail" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="用户名">
              <a-input v-model:value="userDetails.username" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="昵称">
              <a-input v-model:value="userDetails.nickname" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="头像">
              <a-input v-model:value="userDetails.avatar" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="生日">
              <a-date-picker v-model:value="userDetails.birthday" show-time valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 80%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="住址">
              <a-input v-model:value="userDetails.address" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="密码">
              <a-input-password v-model:value="userDetails.password" style="width: 80%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="部门">
              <a-select style="margin-bottom: 10px; margin-right: 10px; width: 80%;" placeholder="请选择部门" v-model:value="deptSelectValue">
                <a-select-option v-for="deptSelect in this.deptSelectList" :key="deptSelect.value" :value=deptSelect.value>{{ deptSelect.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="角色">
              <a-select style="margin-bottom: 10px; margin-right: 10px; width: 80%;" placeholder="请选择角色" v-model:value="roleSelectValue">
                <a-select-option v-for="roleSelect in this.roleSelectList" :key="roleSelect.value" :value=roleSelect.value>{{ roleSelect.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" style="text-align: center; margin-bottom: 50px;">
            <a-button type="primary" @click="confirmRegister">确定</a-button>
          </a-col>
        </a-row>

      </a-modal>

    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import { message } from 'ant-design-vue';

import * as UserApi from '@/api/UserApi.js';

export default {
  name: 'UserList',
  components: {
    MainPanel
  },
  data() {
    return {
      loading: false,
      total: 0,
      dataSource: [],

      columns: [
        {
          title: '序号',
          dataIndex: 'xid',
          key: 'xid',
          fixed: 'left',
          align: 'center',
          width: 80,
        },
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   key: 'id',
        //   align: 'center',
        // },
        {
          title: '手机号',
          dataIndex: 'phone',
          key: 'phone',
          align: 'center',
        },
        {
          title: '邮箱',
          dataIndex: 'mail',
          key: 'mail',
          align: 'center',
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
          align: 'center',
        },
        {
          title: '是否锁定',
          dataIndex: 'isLock',
          key: 'isLock',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          sorter: true,
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 200,
        }
      ],

      userForm: {
        currentPage: 1,
        pageSize: 10,
        id: '',
        phone: '',
        username: '',
        mail: '',
        isLock: '',
        createTimeBegin: '',
        createTimeEnd: '',
        sorter: '',
      },

      // 详情
      showDetails: false,
      userDetails: {},
      userDeptRoleList: [],
      detailsColumns: [
        {
          title: '部门名称',
          dataIndex: 'deptName',
          key: 'deptName',
          align: 'center',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          align: 'center',
        },

      ],

      // 修改
      showUpdate: false,

      updateColumns: [
        {
          title: '部门名称',
          dataIndex: 'deptName',
          key: 'deptName',
          align: 'center',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 200,
        }
      ],

      userId: '',

      roleSelectList: [],
      deptSelectList: [],
      roleSelectValue: '',
      deptSelectValue: '',

      // 注册
      showCreate: false,

      // 删除
      selectedRowKeys: [],

      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log("---selectedRowKeys---", selectedRowKeys);
          console.log("---selectedRows---", selectedRows);
          this.selectedRowKeys = selectedRowKeys;
        },

      },


    }
  },

  methods: {
    userPaging() {
      this.loading = true;
      UserApi['userPagingApi'](this.userForm)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          this.dataSource = result.data.data.list;
          this.total = parseInt(result.data.data.total);
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        this.loading = false;
      })
    },

    // 清空
    clearForm() {
      // this.$refs['userForm'].resetFields();
      this.userForm.username = '';
      this.userForm.phone = '';
      this.userForm.mail = '';
      this.userForm.isLock = '';

      this.userForm.createTimeBegin = '';
      this.userForm.createTimeEnd = '';
    },

    // 分页组件
    onPagination(currentPage, pageSize) {
      this.userForm.currentPage = currentPage;
      this.userForm.pageSize = pageSize;
      this.userPaging();
    },

    // 查询
    onSearch() {
      this.userForm.currentPage = 1;
      this.userPaging();
    },

    // 点击table上的排序
    handleTableChange(pag, filters, sorter) {
      if (sorter.order === "ascend") {
        if (sorter.columnKey === "createTime") {
          this.userForm.sorter = "-createTime";
        }

      } else if (sorter.order === "descend") {
        if (sorter.columnKey === "createTime") {
          this.userForm.sorter = "+createTime";
        }

      } else {
        if (sorter.columnKey === "createTime") {
          this.userForm.sorter = "";
        }

      }
      this.onSearch();
    },

    // 详情
    details(id) {
      this.userId = id;
      this.findUserDetails();
      this.showDetails = true;
    },

    findUserDetails() {
      UserApi['userDetailsApi'](`userId=${this.userId}`)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          let data = result.data.data;
          if (code === 200) {
            this.userDetails = data['userDetails'];
            this.userDeptRoleList = data['userDeptRoleList'];
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

    // 修改
    onUpdate(id) {
      this.userId = id;
      UserApi['userDetailsApi'](`userId=${id}`)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        let data = result.data.data;
        if (code === 200) {
          this.userDetails = data['userDetails'];
          this.userDeptRoleList = data['userDeptRoleList'];
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
      this.findRoleDeptSelect();
      this.showUpdate = true;
    },

    // 确认修改
    confirmUpdate() {
      UserApi['userUpdateApi'](this.userDetails)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          message.success(msg);
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      });
    },

    // 取消授权
    cancelAuthorization(record) {
      let params = {userId: this.userId, roleId: record.roleId, deptId: record.deptId}
      UserApi['cancelAuthorizationApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            this.findUserDetails();
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        });
    },

    // 查询角色部门下拉框
    findRoleDeptSelect() {
      UserApi['findRoleDeptSelectApi']()
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          let data = result.data.data;
          if (code === 200) {
            this.roleSelectList = data['roleSelectList'];
            this.deptSelectList = data['deptSelectList'];
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        });
    },

    // 授权
    authorization() {
      let params = {userId: this.userId, roleId: this.roleSelectValue, deptId: this.deptSelectValue}
      UserApi['authorizationApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            this.findUserDetails();
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        });

    },

    // 锁定或解锁
    lockOrUnlock(record) {
      let params = {userId: this.userId, roleId: record.roleId, deptId: record.deptId}
      if (record.isLock === 1) {
        params = {...params, isLock: 0}
      } else {
        params = {...params, isLock: 1}
      }
      UserApi['lockOrUnlockApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            this.findUserDetails();
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })

    },

    // 注册用户
    onRegister() {
      this.findRoleDeptSelect();
      this.showCreate = true;
    },

    confirmRegister() {
      let params = {...this.userDetails, deptId: this.deptSelectValue, roleId: this.roleSelectValue}
      UserApi['registerApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
            this.userPaging();
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

    // 批量删除
    onDelete(idList) {
      let params = {idList: idList};
      UserApi['userDeleteApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
            this.userPaging();
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        })
    },

    onBatchDelete() {
      if (this.selectedRowKeys.length === 0) {
        message.warning("请选择需删除的用户...");
        return;
      }
      this.onDelete(this.selectedRowKeys);
      this.selectedRowKeys = [];
    },


  },

  created() {
    this.userPaging();
  },

  mounted() {

  },

}
</script>


<style>

</style>
